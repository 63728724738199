#developers-list {
  .filter-section {
    .selected-title {
      background: rgba(47, 128, 237, 0.25);
      border-radius: 5px;
      color: var(--primary-color);
      padding: 10px 8px;
    }
    .filter-label {
      font-weight: bold;
    }
  }
  .developer-section {
    .col-md-4:nth-child(3n + 1) {
      padding-left: 0;
    }
    .col-md-4:nth-child(3n + 3) {
      padding-right: 0;
    }
    .developer-item {
      box-shadow: var(--border-shadow);
      border-radius: 5px;
      height: 100%;
      .card {
        height: inherit;
        overflow-y: hidden;
        cursor: pointer;
        .devtool-list {
          height: 62px;
          overflow-y: hidden;
        }
        .card-body {
          background-color: var(--card-bg);
        }
      }
    }
  }
}

.devtool-item {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: var(--primary-color);
  background: rgba(47, 128, 237, 0.25);
  border-radius: 5px;
  padding: 2px 5px 3.5px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

#developer-detail {
  box-shadow: var(--border-shadow);
  background-color: var(--card-bg);
  border-radius: 5px;
  margin: 25px 0 25px 0;
  position: relative;
  min-width: 600px;
  max-width: 800px;
  padding-bottom: 30px;

  .dev-detail-header {
    background: var(--dark-accent);
    width: 100%;
    height: 105px;
    margin-bottom: 70px;
    border-radius: 5px 5px 0 0;
  }
  .avatar {
    background: var(--light-color);
    height: 140px;
    width: 140px;
    border-radius: 360px;
    border: solid var(--dark-accent) 10px;
  }
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

@media screen and (max-width: 700px) {
  #developer-modal {
    min-width: 60%;
    max-width: 80%;
    height: 90vh;
    overflow: scroll;

    #developer-detail {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

#developer-modal-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(51, 51, 51, 0.2);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}
