header {
  background-color: var(--header-bg);
  box-shadow: var(--border-shadow);
  .navbar {
    a:hover {
      text-decoration: none;
    }
    .navbar-brand img {
      height: 40px;
    }
    .nav-item {
      margin-right: 15px;
    }
    .navbar-nav:first-child {
      margin-left: 15px;
    }
    .btn-join-us {
      height: 32px;
      font-size: 0.775rem;
    }

    @media screen and (max-width: 700px) {
      .nav-item {
        margin: 0;
        width: 100%;
        padding: 5px 0 5px 0;
        text-align: left;
      }

      .navbar-nav:first-child {
        margin: 0;
      }
    }
  }
}
