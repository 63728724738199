.footer {
  background-color: var(--footer-bg);
  color: var(--footer-txt-color);
  padding: 50px 0 30px 0;
  ul {
    list-style: none;
    margin: 20px 0;
    padding: 0;
  }
  .join-us li {
    display: inline-block;
    margin-right: 25px;
  }
  ul.locale-selector {
    li {
      a {
        color: #fff;
        img {
          margin-right: 10px;
        }
      }
    }
    .locale-selector-dropdown {
      button img {
        margin-right: 10px;
      }
    }
  }
  .site a {
    display: inline-block;
    margin-bottom: 10px;
    color: var(--white);
    text-decoration: none;
    font-size: 1.125rem;
  }
  .desc-img {
    margin-bottom: 25px;
  }
  .description {
    img {
      height: 32px;
    }
    p {
      text-align: justify;
      font-size: 1.125rem;
    }
  }
  .dark-switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 26px;
    margin: auto;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 34px;
      background-color: #080606;
      font-size: 17px;
      padding: 2px 3px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 3px;
      border-radius: 50%;
      background-color: var(--bg-white);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #eee;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #eee;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
}
